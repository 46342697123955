<template>
  <div class="page-wrapper">
    <h1 class="title">忘記密碼</h1>
    <BaseElForm ref="formRef" :model="formData" :rules="formRules">
      <BaseElFormItem prop="email" :label="displayText.formItemLabel.email">
        <BaseElInput
          v-model="formData.email"
        />
      </BaseElFormItem>
    </BaseElForm>

    <div class="flex flex-col items-center" style="gap: 24px;">
      <RouterLink to="/login">
        <BaseElButton type="text">
          {{ displayText.btn.backLogin }}
        </BaseElButton>
      </RouterLink>

      <BaseElButton :loading="loading" type="primary" @click="onSubmit">
        {{ displayText.btn.submit }}
      </BaseElButton>
    </div>
  </div>
</template>

<script>
import { SendForgetPasswordAuthnEmail } from '@/api/auth'
import { useBaseForm } from '@/use/useForm'
import { emailRules, noEmptyRules } from '@/validation'
import { computed, defineComponent, onBeforeMount } from 'vue'
import store from '@/store'
import { get } from 'lodash'
import { useRoute } from 'vue-router/composables'

export default defineComponent({
  name: 'ForgotPassword',
  setup (props, { emit }) {
    const route = useRoute()
    const { formRef, formData, initFormData, loading, checkForm } = useBaseForm()
    initFormData({
      email: null,
    })
    const formRules = computed(() => {
      const rules = {
        email: [noEmptyRules(), emailRules()],
      }
      return rules
    })

    const orgId = computed(() => get(store.state, 'org.org.id'))

    const queryEmail = computed(() => get(route.query, 'email'))

    const displayText = computed(() => {
      const text = {
        pageTitle: '忘記密碼',
        formItemLabel: {
          email: '輸入電子信箱',
        },
        btn: {
          submit: '寄送密碼重置信',
          backLogin: '返回登入頁',
        },
        hint: '若無法收到密碼重置信，請聯繫集團管理員或小幫手',
      }
      return text
    })

    const onSubmit = async () => {
      if (loading.value) return
      const isValid = await checkForm(formRef.value)
      if (!isValid) {
        loading.value = false
        return
      }

      const [, err] = await SendForgetPasswordAuthnEmail({
        orgId: orgId.value,
        email: formData.email,
      })
      loading.value = false

      if (err) {
        window.$message.error(err)
        return
      }

      window.$message.success('密碼重置信已寄出，請至信箱查收')
    }

    onBeforeMount(() => {
      if (queryEmail.value) {
        formData.email = queryEmail.value
      }
    })

    return { displayText, formRules, formRef, formData, loading, onSubmit }
  },
})
</script>

<style lang="postcss" scoped>
.page-wrapper {
  @apply flex flex-col items-center justify-center h-full;
}
.title {
  @apply text-[32px] font-medium text-center;
}
</style>
